<template>
  <a-modal
    :get-container="props.modalRef"
    v-model:visible="visible"
    :mask-closable="false"
    title="新增会员优惠券"
    :after-close="afterClose"
    :width="600"
  >
    <template #footer>
      <a-button :disabled="disabled" @click="visible = false">取消</a-button>
      <a-button :disabled="disabled" type="primary" @click="doSubmit"
        >确定</a-button
      >
    </template>
    <a-form :label-col="{ span: 6 }">
      <a-form-item label="优惠券名称" v-bind="validateInfos.couponPackageName">
        <a-input
          v-model:value="couponVo.couponPackageName"
          :maxlength="20"
          placeholder="请输入"
          allow-clear
        ></a-input>
      </a-form-item>
      <a-form-item label="优惠券类型" v-bind="validateInfos.couponType">
        <a-select
          v-model:value="couponVo.couponType"
          placeholder="请选择"
          allow-clear
          @change="changeCouponType"
        >
          <a-select-option
            v-for="item in couponTypes"
            :key="item.value"
            :value="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="额度" v-bind="validateInfos.couponValue">
        <a-input
          v-model:value="couponVo.couponValue"
          :maxlength="couponVo.couponType === 1 ? 3 : 6"
          :placeholder="`${
            couponVo.couponType === 1
              ? '请输入折扣, 范围1-9.9折'
              : '请输入金额, 范围1-1000元'
          }`"
          allow-clear
          :suffix="`${couponVo.couponType === 1 ? '折' : '元'}`"
        ></a-input>
      </a-form-item>
      <a-form-item label="车场类型" v-bind="validateInfos.parkType">
        <a-select
          v-model:value="couponVo.parkType"
          placeholder="请选择"
          allow-clear
        >
          <a-select-option
            v-for="item in parkTypes"
            :key="item.value"
            :value="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="发行总量" v-bind="validateInfos.publishNum">
        <!-- <a-input
          v-model:value="couponVo.publishNum"
          :maxlength="5"
          placeholder="请输入"
          type="number"
          oninput="value=value.replace(/[^0-9]/g,'')"
          allow-clear
        ></a-input> -->
        <a-input-number
          v-model:value="couponVo.publishNum"
          :min="1"
          :precision="0"
          :max="100000"
          placeholder="请输入1-10000"
          allow-clear
          style="width: 100%"
        />
      </a-form-item>
      <!-- <a-form-item label="优惠券领取有效时间">
        <a-radio-group
          v-model:value="effectiveDayType"
          @change="changeEffcetiveDayType"
        >
          <a-radio :value="1">领取后N天内有效</a-radio>
          <a-radio :value="2">指定有效时段</a-radio>
        </a-radio-group>
      </a-form-item> -->
      <a-form-item
        label="优惠券有效天数"
        v-bind="validateInfos.effectiveDay"
        v-show="effectiveDayType === 1"
      >
        <a-input-number
          v-model:value="couponVo.effectiveDay"
          :min="1"
          :precision="0"
          :max="1000"
          placeholder="请输入优惠券有效天数(整数)"
          allow-clear
          style="width: 100%"
        ></a-input-number>
      </a-form-item>
      <a-form-item
        label="有效时段"
        v-bind="validateInfos.useDate"
        v-show="effectiveDayType === 2"
      >
        <a-range-picker
          v-model:value="couponVo.useDate"
          show-time
          :disabled-date="disabledDate"
          @change="changeUseDate"
        />
      </a-form-item>
      <a-form-item label="发放时间" v-bind="validateInfos.pubDate">
        <a-range-picker
          v-model:value="couponVo.pubDate"
          show-time
          :disabled-date="disabledDate"
          @change="changePubDate"
        />
      </a-form-item>
      <a-form-item label="备注" v-bind="validateInfos.remark">
        <a-textarea
          v-model:value="couponVo.remark"
          :maxlength="200"
          :rows="4"
          placeholder="请输入"
          allow-clear
        ></a-textarea>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits } from "vue";
import useCouponVIP from "@/hooks//coupon/useCouponVIP";
import useValidateRules from "@/hooks/useValidateRules";
import useMessage from "@/hooks/useMessage";
import { Form } from "ant-design-vue";
import { add } from "@/api/coupon/couponVipApi";
import { useStore } from "vuex";

const useForm = Form.useForm;
const store = useStore();
const props = defineProps(["modalRef"]);
const emit = defineEmits(["close"]);
const disabled = ref(false);
const effectiveDayType = ref(1);
const {
  couponVo,
  couponTypes,
  parkTypes,
  changeUseDate,
  changePubDate,
  validatePubDate,
  validatePubDate1,
  validateCouponValue,
  validateCouponValueByCash,
  disabledDate,
} = useCouponVIP();
const { couponVipVoRules } = useValidateRules();
const { showErr, showConfirm, showLoading, closeLoading, showTipMessage } =
  useMessage();
const { validateInfos, validate, rulesRef } = useForm(
  couponVo,
  couponVipVoRules,
);
const visible = ref(true);
let refresh = false;
const afterClose = () => {
  emit("close", refresh);
};
const doSubmit = () => {
  validate().then(async () => {
    let action = await showConfirm("添加新的会员优惠券?");
    if (action) {
      disabled.value = true;
      await showLoading();
      // eslint-disable-next-line no-unused-vars
      const {
        couponPackageName,
        couponType,
        parkType,
        couponValue,
        publishNum,
        effectiveDay,
        useStartDate,
        useEndDate,
        pubStartDate,
        pubEndDate,
        remark,
      } = couponVo;
      try {
        const params = {
          couponPackageName,
          couponType,
          parkType,
          couponValue,
          publishNum: Number.parseInt(publishNum),
          effectiveDay: Number.parseInt(effectiveDay),
          useStartDate,
          useEndDate,
          pubStartDate,
          pubEndDate,
          remark,
          createAccount: store.state.user.name,
        };
        await add(params);
        await closeLoading();
        await showTipMessage("添加成功");
        refresh = true;
        visible.value = false;
      } catch (e) {
        await closeLoading();
        await showErr(e);
        disabled.value = false;
      }
    }
  });
};
// const changeEffcetiveDayType = e => {
//   effectiveDayType.value = e.target.value;
//   const required = e.target.value === 1; // true则领取后N天有效校验有效天数, 不检验有效时段 false则反之
//   dynamicValidate(required);
// };
/**
 * @description 动态添加规则
 * validateInfos 校验信息
 * rulesRef 校验规则节点
 */
const dynamicValidate = required => {
  validateInfos.effectiveDay.required = required;
  rulesRef.effectiveDay[0].required = required;
  validateInfos.useDate.required = !required;
  rulesRef.useDate[0].required = !required;
  if (!required) {
    rulesRef.pubDate[0].validator = validatePubDate;
    couponVo.effectiveDay = null;
  } else {
    couponVo.useDate = null;
    couponVo.useStartDate = "";
    couponVo.useEndDate = "";
    rulesRef.pubDate[0].validator = validatePubDate1;
  }
};
const changeCouponType = val => {
  if (val === 1) {
    rulesRef.couponValue[0].validator = validateCouponValue;
  }
  if (val === 2) {
    rulesRef.couponValue[0].validator = validateCouponValueByCash;
  }
};
onMounted(async () => {
  dynamicValidate(true);
  rulesRef.couponValue[0].validator = validateCouponValueByCash;
});
</script>

<style scoped></style>
